import React, { ComponentState, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getter } from "@progress/kendo-react-common";
import { getComplianceReport, getSiteList, loaderAction, storeComplianceReport } from "src/redux/actions";
import { CompleteComplianceResponse } from "src/dataModels/clientResponse";
import { GridColumn, GridNoRecords, Grid, getSelectedState } from "@progress/kendo-react-grid";
import { ComplianceReport, ComplianceReportList } from "src/dataModels/complianceModel";
import { Chip } from "@progress/kendo-react-buttons";
import { IS_GLOBAL_SEARCH, SELECTED_CLIENT_ID, SELECTED_CLIENT_NAME } from "src/redux/actions/types";
import { useNavigate } from "react-router";
import APP_ROUTES from "src/helper/app-routes";
import { orderBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import apiHelper from "src/helper/api-helper";
import { showError } from "src/util/utility";
import MultiSelectDropDown from "src/control-components/multi-select-drop-down/multi-select-drop-down";
import { DropDownObjectWithNumber } from "src/dataModels/docListModel";
import { clinicServices } from "src/services/clinicService";
import moment from "moment";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import ComplianceExportToExcel from "./compliance-export-to-excel";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const ClientComplianceReport = () => {
    const [isComplianceFetched, setIsComplianceFetched] = useState(false)
    const [cpReportList, saveReportList] = useState<ComplianceReportList[]>([])
    const [cpReportColumns, setCpReportColumns] = useState<[]>([])
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [sort, setSort] = useState<any>([]);
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const outsideRef = useRef(null);
    const globalSite = state.selectedAppSite;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { complianceReport, complianceReportData } = state['ClinicsReducer'];
    const [selectedState, setSelectedState] = React.useState({});
    const [siteList, setSiteList] = useState([]);
    const { sitesList } = state['DocumentReducer'];
    const [selectedSite, setSite] = useState({ id: globalSite.siteId, name: globalSite.siteName });
    const refSiteClose = useRef<HTMLInputElement>(null);
    const refSite = useRef<HTMLInputElement>(null);
    const [openSiteFilter, setOpenSiteFilter] = useState(false)
    const [filter, setFilter] = useState<any>();
    const [patientList, setPatientList] = useState([]);
    const [insuranceData, setInsuranceData] = useState([]);

    const [fields, setFields] = useState<any>({
        client: [],
        clientSite: { id: globalSite.siteId, name: globalSite.siteName },
        isActive: true,
        payer: [],
        compliance: [],
    });

    const refClient = useRef<HTMLInputElement>(null);
    const refClientClose = useRef<any>(null);
    const [openClientFilter, setOpenClientFilter] = useState(false);
    const refPayer = useRef<HTMLInputElement>(null);
    const refPayerClose = useRef<HTMLInputElement>(null);
    const [openPayerFilter, setOpenPayerFilter] = useState(false);
    const refCpItem = useRef<HTMLInputElement>(null);
    const refCpItemClose = useRef<HTMLInputElement>(null);
    const [openCpFilter, setOpenCpFilter] = useState(false);
    const [complianceSelectedItem, setSelectedComplianceItem] = useState([]);
    const [complianceList, setComplianceList] = useState<DropDownObjectWithNumber[]>([]);



    useEffect(() => {

        if (complianceReportData && complianceReportData?.payload) {
            setFields({
                clientSite: complianceReportData?.payload?.clientSite,
                client: complianceReportData?.payload?.client,
                payer: complianceReportData?.payload?.payer,
                compliance: complianceReportData?.payload?.compliance
            })
        }

    }, [complianceReportData])

    useEffect(() => {
        if (sitesList != null) {
            setSiteList(sitesList);
        }
    }, [sitesList]);


    useEffect(() => {
        if (!isComplianceFetched) {
            fetchPatientList()
            getInsuranceTypes()
            getComplianceList()
            dispatch(getSiteList({}));
            // dispatch(getComplianceReport(fields))
            setIsComplianceFetched(true)
        }
    }, [isComplianceFetched])

    useEffect(() => {
        if (complianceReport && complianceReport.length > 0) {
            saveReportList(complianceReport)
            var names = complianceReport[0].complianceReport.map(function (item) {
                return { name: item['complianceName'], id: item['complianceId'] };
            });
            setCpReportColumns(names)
        } else {
            saveReportList([])
        }
    }, [complianceReport])


    const getInsuranceTypes = () => {

        apiHelper.getRequest(API_ENDPOINTS.GET_INSURANCE_TYPE)
            .then((result) => {
                let insuranceList = result.resultData;
                setInsuranceData(insuranceList);
            })
            .catch((error) => {
                showError(error.message);
            });
    };


    function fetchPatientList() {
        apiHelper.getRequest(API_ENDPOINTS.GET_CLIENT_DDL_BY_CLINIC_ID+true)
            .then((result) => {
                const list = result.resultData.map((r) => {
                    return { id: r.id, name: r.clientName };
                });
                setPatientList(list);
            })
            .catch((err) => {
                showError(err, "Patient List");
            })
            .finally(() => {

            });
    }

    function getComplianceList() {
        apiHelper.getRequest(API_ENDPOINTS.GET_COMPLIANCE_FILTERED_LIST)
            .then((result) => {
                const list = result.resultData.map((r) => {
                    return { id: r.complianceId, name: r.name };
                });
                setComplianceList(list);
            })
            .catch((err) => {
                showError(err, "Compliance List");
            })
            .finally(() => {

            });
    }

    function renderToItem(li, itemProps) {
        const itemChildren = (
            <span>
                <input
                    type="checkbox"
                    name={itemProps.dataItem}
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                />
                &nbsp;{li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }




    const handleRowClick = (field) => {
        if (field.clientId) {
            let element = field.clientId;
            dispatch({
                type: SELECTED_CLIENT_ID,
                payload: element,
            });
            dispatch({
                type: SELECTED_CLIENT_NAME,
                payload: field,
            });
            dispatch({
                type: IS_GLOBAL_SEARCH,
                payload: false,
            });
            navigate(APP_ROUTES.CLIENT_DASHBOARD);
        }
    };
    const pageChange = (event) => {
        let skip = event.page.skip;
        let take = event.page.take;
        setPage(skip);
        setPageSize(take)
    };

    function onFilterChange(event, listName) {
        setFilter({ ...filter, [listName]: event.filter });
    }

    const handleChange = (e: any) => {
        const name = e.target.name;
        const value = e.value;
        // if (name === 'clientSite') {
        //     setSite(e.value)
        //     dispatch(storeComplianceReport({
        //         ...fields,
        //         [name]: value

        //     }))
        // }
        setOpenSiteFilter(false)
        saveReportList([])
        setFields({ ...fields, [name]: value })
        dispatch(storeComplianceReport({
            ...fields,
            [name]: value

        }))
    }



    useEffect(() => {
        if (isComplianceFetched) {
            const data = {
                isActive: true, clientSite: fields?.clientSite?.id,
                complianceIds: fields?.compliance?.map((item) => item?.id), clientIds: fields?.client?.map((item) => item?.id), payerIds: fields?.payer?.map((item) => item?.id)
            }
            dispatch(getComplianceReport(data))
        }
    }, [fields, isComplianceFetched])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickListener);

        return () => {
            document.removeEventListener('mousedown', handleClickListener);
        };
    }, []);

    const handleClickListener = async (event) => {

        if (refSite.current && !refSite.current?.contains(event.target)) {
            setTimeout(() => {
                setOpenSiteFilter(false)
            }, 200);
        }
        if (refClient.current && !refClient.current?.contains(event.target)) {
            setTimeout(() => {
                setOpenClientFilter(false)
            }, 200);
        }
        if (refPayer.current && !refPayer.current?.contains(event.target)) {
            setTimeout(() => {
                setOpenPayerFilter(false)
            }, 200);
        }

        if (refCpItem.current && !refCpItem.current?.contains(event.target)) {
            if (event.defaultPrevented) {
                return;
            }
            setTimeout(() => {
                setOpenCpFilter(false)
            }, 200);
        }

    }

    const calculateWidth = (text) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (context) {
            context.font = '16px Manrope';
            const metrics = context.measureText(text);
            const content = context.measureText('completed');
            return metrics.width > content.width ? metrics.width : content.width + 25;
        }
        return 0;
    }

    const handleClearClient = () => {

        setFields({
            ...fields,
            client: [],
        });
        dispatch(storeComplianceReport({
            ...fields,
            client: [],
        }))

        setFilter({ ...filter, 'patientList': null });
    };

    const handleClearPayer = () => {

        setFields({
            ...fields,
            payer: [],
        });
        setFilter({ ...filter, 'payerList': null });
        dispatch(storeComplianceReport({
            ...fields,
            payer: [],
        }))
    };

    const handleClearCompliance = () => {
        setFields({
            ...fields,
            compliance: [],
        });
        dispatch(storeComplianceReport({
            ...fields,
            compliance: [],
        }))
        setSelectedComplianceItem([])
        setFilter({ ...filter, 'complianceList': null });
    };


    const exportComplianceReport = async () => {
        let complianceIds = fields?.compliance.map(a => a.id);
        let clientIds = fields?.client.map(a => a.id);
        let payerIds = fields?.payer.map(a => a.id);
        const exportObject={
        "clientSite": fields.clientSite.id,
        "isActive": true,
        "complianceIds": complianceIds,
        "clientIds": clientIds,
        "payerIds": payerIds
      }
      dispatch(loaderAction(true))
      const response = await clinicServices.exportComplianceReport(exportObject);
      if(response && response.resultData){
        window.open(response.resultData)
      }
      dispatch(loaderAction(false))
    }

    const currentComplianceReport = (currentCp) => {
        if (currentCp?.isCompleted) {
            if (moment(currentCp?.complianceNextDueDate).format("M/D/YYYY") > moment(currentCp?.completedDate).format("M/D/YYYY")){
                return `Next Due on ${moment(currentCp?.complianceNextDueDate).format("M/D/YYYY")}`
            }
        return `Completed on ${moment(currentCp?.completedDate).format("M/D/YYYY")}`
        }

        if(!currentCp?.isApplicable){
            return `NA`   
        }

        if(currentCp?.isRestrictive){
            return `Pending (Due on ${moment(currentCp?.complianceNextDueDate).format("M/D/YYYY")})`
        }

        return `Pending (Due on ${moment(currentCp?.complianceNextDueDate).format("M/D/YYYY")})`
       
      };


    return (
        <div className="d-flex flex-wrap">

            <div className="col-md-12 col-lg-12">

                <div className="Service-RateList m-0">
                    <button
                        type="button"
                        value="BACK"
                        onClick={() => {
                            navigate(-1)
                        }}
                        className="border-0 bg-transparent arrow-rotate mb-3"
                    >
                        <i className="k-icon k-i-sort-asc-sm"></i>
                        Back
                    </button>
                    <div className="d-flex justify-content-between  mt-3">
                        <h4 className="address-title text-grey ">
                            <span className="f-24">Compliance Reports</span>
                        </h4>
                        <div className="d-flex">
                            {/* <button className="submitButon" onClick={() => {
                                exportComplianceReport()
                            }}>Export to Excel
                            </button> */}
                            <ComplianceExportToExcel cpReportList={cpReportList}/>
                        </div>
                    </div>
                </div>
                <div className="row searchBoxShadow row align-items-center mt-2 blue_theme staffButton" >
                    <>
                        <div className="col-lg-9 col-sm-12" >
                            <div
                                className="content-search-filter  px-0 filter-drop-down"
                                ref={outsideRef}
                            >
                                {/*start click major filter */}
                                <div className="major-filter">

                                    <div
                                        onClick={(event: any) => {
                                            if (!refSiteClose.current?.contains(event.target)) {
                                                setOpenSiteFilter(true)
                                            }
                                        }}
                                        ref={refSite}
                                        className="service-main dropdown email-filter border-dashed-cus position-relative"
                                    >
                                        <button className="btn  btn-size-cus pl-0 " type="button">
                                            <i className="fa fa-plus cross-icon "></i>
                                            Site
                                            <span className={fields?.clientSite?.id ? "border-spann" : ''}>
                                                {fields?.clientSite?.name && fields?.clientSite?.name?.split('(')?.length > 0 ? fields?.clientSite.name.split('(')[0] : ""}
                                            </span>
                                        </button>

                                        {openSiteFilter && (
                                            <div className="dropdown-service ">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <DropDownList
                                                            filterable={true}
                                                            data={filterBy(siteList, filter ? filter.siteList : [])}
                                                            onFilterChange={(event) => {
                                                                onFilterChange(event, "siteList");
                                                            }}
                                                            textField="name"
                                                            label="Site"
                                                            name="clientSite"
                                                            value={fields?.clientSite}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>


                                    <div
                                        onClick={(event: any) => {
                                            if (!refClientClose.current?.contains(event.target)) {
                                                setOpenClientFilter(true)
                                            }
                                        }}
                                        ref={refClient}
                                        className="service-main dropdown email-filter border-dashed-cus position-relative"
                                    >
                                        <button className="btn  btn-size-cus pl-0 " type="button">
                                            {fields?.client?.length && fields?.client?.length > 0 ?
                                                <i
                                                    ref={refClientClose}
                                                    onClick={handleClearClient}
                                                    className={"fa fa-times cross-icon mr-2 "}
                                                ></i>

                                                :
                                                <i
                                                    className={"fa fa-plus cross-icon "}

                                                ></i>}

                                            Client {fields?.client?.length !== 0 && (
                                                <span
                                                    className={fields?.client?.length && fields?.client?.length > 0 ? 'border-spann' : ''}
                                                >
                                                    {fields?.client?.at(0)?.name}{(fields?.client?.length && fields?.client?.length > 1) ? '(+' + (fields.client.length - 1) + ')' : ''}

                                                </span>
                                            )}
                                        </button>

                                        {openClientFilter && (
                                            <div className="dropdown-service dropDates ">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <MultiSelectDropDown
                                                            data={filterBy(patientList, filter?.patientList)}
                                                            textField="name"
                                                            label="Client"
                                                            name="client"
                                                            value={fields.client}
                                                            // onChange={(e) => {

                                                            //     setFields({ ...fields, client: e.target.value })
                                                            // }}
                                                            onChange={handleChange}
                                                            autoClose={true}
                                                            dataItemKey={"id"}
                                                            itemRender={renderToItem}
                                                        />

                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        onClick={(event: any) => {
                                            if (!refPayerClose.current?.contains(event.target)) {
                                                setOpenPayerFilter(true)
                                            }
                                        }}
                                        ref={refPayer}
                                        className="service-main dropdown email-filter border-dashed-cus position-relative"
                                    >
                                        <button className="btn  btn-size-cus pl-0 " type="button">
                                            {fields?.payer?.length && fields?.payer?.length > 0 ?
                                                <i
                                                    ref={refPayerClose}
                                                    onClick={handleClearPayer}
                                                    className={"fa fa-times cross-icon mr-2 "}
                                                ></i>

                                                :
                                                <i
                                                    className={"fa fa-plus cross-icon "}

                                                ></i>}

                                            Payer {fields?.payer?.length !== 0 && (
                                                <span
                                                    className={fields?.payer?.length && fields?.payer?.length > 0 ? 'border-spann' : ''}
                                                >
                                                    {fields?.payer?.at(0)?.insuranceName}{(fields?.payer?.length && fields?.payer?.length > 1) ? '(+' + (fields.payer.length - 1) + ')' : ''}

                                                </span>
                                            )}
                                        </button>

                                        {openPayerFilter && (
                                            <div className="dropdown-service dropDates ">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <MultiSelectDropDown
                                                            data={filterBy(insuranceData, filter?.payerList)}
                                                            textField="insuranceName"
                                                            label="Payer"
                                                            name="payer"
                                                            value={fields.payer}
                                                            // onChange={(e) => {
                                                            //     setFields({ ...fields, payer: e.target.value })
                                                            // }}
                                                            onChange={handleChange}
                                                            autoClose={true}
                                                            placeholder="Payer"
                                                            dataItemKey={"id"}
                                                            itemRender={renderToItem}
                                                        />

                                                    </div>

                                                </div>


                                            </div>
                                        )}
                                    </div>


                                    <div
                                        ref={refCpItem}
                                        onClick={(event: any) => {
                                            if (!refCpItemClose.current?.contains(event.target)) {
                                                setOpenCpFilter(true)
                                            }
                                        }

                                        }
                                        className="service-main dropdown email-filter border-dashed-cus position-relative"
                                    >
                                        <button className="btn  btn-size-cus pl-0 " type="button">
                                            {fields?.compliance?.length && fields?.compliance?.length > 0 ? (
                                                <i
                                                    ref={refCpItemClose}
                                                    onClick={handleClearCompliance}
                                                    className={"fa fa-times cross-icon mr-2 "}
                                                ></i>
                                            ) : (
                                                <i
                                                    className={"fa fa-plus cross-icon "}

                                                ></i>
                                            )}     Compliance
                                            {fields?.compliance?.length !== 0 && (
                                                <span className={fields?.compliance ? "border-spann" : ''}>
                                                    {fields?.compliance?.at(0)?.name} {(fields?.compliance?.length && fields?.compliance?.length > 1) ? '(+' + (fields?.compliance.length - 1) + ')' : ''}
                                                </span>
                                            )}
                                        </button>

                                        {openCpFilter && (
                                            <div className="dropdown-service dropInputField">
                                                <div className="row justify-content-end">
                                                    <div ref={refCpItemClose} className="col-lg-4 col-md-8 col-sm-12">
                                                        <div className="text-center ">
                                                            <button onClick={(event: any) => {
                                                                setOpenCpFilter(false)
                                                                setFields({ ...fields, compliance: complianceSelectedItem })
                                                                dispatch(storeComplianceReport(
                                                                    { ...fields, compliance: complianceSelectedItem })
                                                                )
                                                            }} type="button" className="submitButon mr-0">
                                                                Apply
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" >
                                                        <MultiSelectDropDown
                                                            data={complianceList}
                                                            textField="name"
                                                            label="Compliance"
                                                            name="compliance"
                                                            value={complianceSelectedItem}
                                                            onChange={(e: any) => {
                                                                setSelectedComplianceItem(e.target.value)
                                                            }}
                                                            autoClose={false}
                                                            dataItemKey={"id"}
                                                            itemRender={renderToItem}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>



                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12 d-flex justify-content-end clear-add-filter">
                            <div className="mr-3" onClick={() => {
                                setFilter({ ...filter, 'patientList': null, 'payerList': null, 'complianceList': null, 'siteList': null });
                                setSelectedComplianceItem([])
                                setFields({
                                    ...fields, client: [],
                                    clientSite: globalSite.siteId,

                                    payer: [],
                                    compliance: [],
                                })
                                dispatch(storeComplianceReport({
                                    ...fields, client: [],
                                    clientSite: globalSite.siteId,
                                    payer: [],
                                    compliance: [],
                                }))
                                setSite({ id: globalSite.siteId, name: globalSite.siteName })
                            }}>
                                <p className="mb-0" >
                                    Clear Filter
                                </p>
                            </div>

                        </div>

                    </>



                </div>

                <div className="grid-table blueThemeTable filter-grid">
                    <div className="address-line-content mt-3 custom-report-table">
                        <Grid
                            data={orderBy(cpReportList, sort).map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)], })).slice(page, pageSize + page)}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            skip={page}
                            take={pageSize}
                            total={cpReportList ? cpReportList?.length : 0}
                            onPageChange={pageChange}
                            className="pagination-row-cus"
                            pageable={{ pageSizes: [10, 20, 30] }}
                            sortable={true}
                            style={{ width: '100%' }}>
                            <GridColumn
                                className="cursor-default"
                                cell={(props) => {
                                    let field = props?.dataItem;
                                    return (
                                        <>
                                            <td className="position-sticky start-0 bg-custom-color">
                                                <span
                                                    className="position-sticky start-0 bg-custom-color cursor-default"
                                                    onClick={() => {
                                                        handleRowClick(field);
                                                    }}
                                                >
                                                    {field.lName + ', ' + field.fName + '(' + field.age + ')'}
                                                </span>
                                            </td>
                                        </>
                                    );
                                }}

                                title="Name"
                                width={150}
                                locked={true}
                            />

                            <GridColumn
                                className="cursor-default"
                                field="siteName"
                                title="Sites"
                                width={200}
                            />

                            <GridColumn
                                className="cursor-default"
                                field="payerName"
                                title="Payer"
                                width={200}
                            />

                            <GridColumn
                                className="cursor-default"
                                field="status"
                                title="Status"
                                width={100}
                            />
                            {
                                (cpReportColumns && cpReportColumns.length > 0)
                                && cpReportColumns.map((columnRecord: any) => {
                                    const columnWidth = calculateWidth(columnRecord.name);

                                    return (
                                        <GridColumn
                                            title={columnRecord.name}
                                            // width={columnWidth}
                                            width={230}
                                            cell={(props) => {
                                                let field: ComplianceReport[] = props?.dataItem?.complianceReport;
                                                const currentCp = field.find((i) => i.complianceId === columnRecord.id);
                                                return (
                                                    <td className="cursor-default chip-td">
                                                        <Chip
                                                            text={currentComplianceReport(currentCp)}
                                                            value="chip"
                                                            rounded={"large"}
                                                            fillMode={"solid"}
                                                            size={"medium"}
                                                            className="chip-complete"
                                                            style={{
                                                                marginRight: 5,
                                                                backgroundColor: (currentCp?.isCompleted || !currentCp?.isApplicable) ? '#28a745' :
                                                                    (!currentCp?.isCompleted && currentCp?.isApplicable && currentCp?.isRestrictive) ? '#dc3545' : '#ffc107',
                                                                marginBottom: 5,
                                                                color: "#ffffff",
                                                            }}

                                                        />
                                                    </td>
                                                );
                                            }}
                                        />
                                    )
                                })
                            }
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientComplianceReport