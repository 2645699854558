import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import React, { ComponentState, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePickerKendoRct from "src/control-components/date-picker/date-picker";
import { filterBy } from "@progress/kendo-data-query";
import { showError } from "src/util/utility";
import apiHelper from "src/helper/api-helper";
import { Skeleton, TableCell } from "@mui/material";
import { customAxios } from "src/services/useExPressApi";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { TableRowsLoader } from "src/control-components/custom-skelton";
import calender from "../../assets/images/calendar-03.png";
import GridLoader from "src/control-components/loader/loader";
import {
    Grid,
    GridColumn,
} from "@progress/kendo-react-grid";
import { userIsAdmin, userIsSuperAdmin } from "src/helper/permission-helper";
import { getClockStatus, saveUpdateStaffTimeClockByIdResponse } from "src/redux/actions";
import { useNavigate } from "react-router";
import AccessDenied from "../access/access-denied";

const GenerateTimeClockReport = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const latestDataRef = useRef(null);
    const [openDateFilter, setOpenDateFilter] = useState(false);
    const [applyButtonClicked, setApplyButtonClicked] = useState(false);
    const staffId = useSelector((state: ComponentState) => state.loggedIn?.staffId);
    const date = new Date();
    const [staffTimeClock, setStaffTimeClock] = useState([]);
    const [loading, setLoading] = useState(false);
    const result = subtractDays(7, date);
    function subtractDays(numOfDays, date = new Date()) {
        const dateCopy = new Date(date.getTime());
        dateCopy.setDate(dateCopy.getDate() - numOfDays);
        return dateCopy;
    }
    const [staffError, setStaffError] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const staffLoginInfo = useSelector((state: ComponentState) => state.getStaffReducer);
    const reason: any = {
        id: "",
        name: ""
    }
    const clearObj = {
        'staffId': (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)) ? staffId : null,
        'startDate': result,
        'endDate': date,
        'reason': reason

    }
    const reasonData = [{
        id: "Sick Leave", name: "Sick Leave"
    },
    { id: "Vacation Leave", name: "Vacation Leave" },
    { id: "PTO (Paid Time Off)", name: "PTO (Paid Time Off)" },
    { id: "Maternity Leave", name: "Maternity Leave" },
    { id: "Not Near Computer", name: "Not Near Computer" },
    { id: "Forgot to Clock In", name: "Forgot to Clock In" },
    { id: "Forgot to Clock Out", name: "Forgot to Clock Out" },
    { id: "Other", name: "Other" },
    ]
    const [filter, setFilter] = React.useState<any>();
    const [fields, setFields] = useState(clearObj)
    const refStaff = useRef<HTMLInputElement>(null);
    const refReason = useRef<HTMLInputElement>(null);
    const refStaffClose = useRef<HTMLInputElement>(null);
    const refReasonClose = useRef<HTMLInputElement>(null);
    const [openStaffFilter, setOpenStaffFilter] = useState(false);
    const [openReasonFilter, setOpenReasonFilter] = useState<boolean>(false);
    const [staffList, setStaffList] = useState([]);
    const [loadingStaff, setLoadingStaffList] = useState(false);
    const [loadingClockList, setLoadingClockList] = useState(false);
    const [isInitialDataFetched, setIsInitialDataFetched] = useState(false);
    const state = useSelector((states: ComponentState) => { return states; });
    const { timeclockStatus, updateClockTimeResponse } = state['DashboardReducer']
    const isHumanResourcesManager = state.getRolePermission.isHumanResourcesManager



    useEffect(() => {
        document.addEventListener('mousedown', handleClickListener);
        return () => {
            document.removeEventListener('mousedown', handleClickListener);
        };
    }, []);
    const handleClickListener = async (event) => {
        if (refStaff.current && !refStaff.current?.contains(event.target)) {
            setTimeout(() => {
                setOpenStaffFilter(false)

            }, 200);
        }
        if (refReason.current && !refReason.current?.contains(event.target)) {
            setTimeout(() => {
                setOpenReasonFilter(false)

            }, 200);
        }
    }

    useEffect(() => {
        if (updateClockTimeResponse === true) {
            getClockStatus()
            fetchClockTimeList(
                fields?.staffId?.id ? fields?.staffId?.id :
                    (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)) && (!staffLoginInfo.isHumanResourcesManager)
                        ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null,
                fields.startDate, fields.endDate, fields.reason)
            dispatch(saveUpdateStaffTimeClockByIdResponse(false))
        }
        fetchStaffList();
    }, [updateClockTimeResponse])

    useEffect(() => {
        if (!isInitialDataFetched) {
            fetchClockTimeList(
                fields?.staffId?.id ? fields?.staffId?.id :
                    (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)) && (!staffLoginInfo.isHumanResourcesManager)
                        ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null,
                fields.startDate, fields.endDate, fields.reason)
            setIsInitialDataFetched(true)
        }
    }, [isInitialDataFetched]);
    function onFilterChange(event, listName) {
        setFilter({ ...filter, [listName]: event.filter });
    }
    const clearAllFilter = () => {
        setFields({
            ...fields, 'staffId':
                (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)) && (!staffLoginInfo.isHumanResourcesManager) ? staffId : null,
            'startDate': result,
            'endDate': date,
            reason: ""
        })
        setFilter({})
        fetchClockTimeList((!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)) && (!staffLoginInfo.isHumanResourcesManager) ? staffId : null, result, date, "")
    }

    const handleClearStaff = () => {
        setFields({
            ...fields, 'staffId':
                (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)) && (!staffLoginInfo.isHumanResourcesManager) ? staffId : null
        })
        setFilter({})
        fetchClockTimeList((!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)) && (!staffLoginInfo.isHumanResourcesManager) ? staffId : null, result, date, fields.reason.name)
    }

    const handleClearReason = () => {
        setFields({
            ...fields, 'reason': ""
        })
        setFilter({})
        fetchClockTimeList(fields?.staffId?.id ? fields?.staffId?.id :
            (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)) && (!staffLoginInfo.isHumanResourcesManager)
                ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null, result, date, "")
    }

    const handleApplyFilter = (event) => {
        setStaffError(true);
        setTimeout(() => {
            setOpenDateFilter(false)
        }, 200);
        fetchClockTimeList(fields?.staffId?.id, fields.startDate, fields.endDate, fields.reason)
    };

    useEffect(() => {

        if (isInitialDataFetched) {
            fetchClockTimeList(
                fields?.staffId?.id ? fields?.staffId?.id :
                    (!userIsSuperAdmin(staffLoginInfo?.roleId) || !userIsAdmin(staffLoginInfo?.roleId)) && (!staffLoginInfo.isHumanResourcesManager)
                        ? staffId : (fields.staffId && fields.staffId.id) ? fields.staffId.id : null,
                fields.startDate, fields.endDate, fields.reason)
        }
    }, [timeclockStatus])

    function fetchStaffList() {
        setLoadingStaffList(true);
        apiHelper.getRequest(API_ENDPOINTS.GET_STAFF_DDL_BY_CLINIC_ID + "true")
            .then((result) => {
                const list = result.resultData.map((r: any) => {
                    return { id: r.id, name: r.name };
                });
                setStaffList(list);
            })
            .catch((err) => {
                showError(err, "Staff List");
            })
            .finally(() => {
                setLoadingStaffList(false);
            });
    }

    async function fetchClockTimeList(staffIds: number, startDates: Date, endDates: Date, reason: string) {
        setLoadingClockList(true)
        const clockData = await customAxios.post<any, any>(`${API_ENDPOINTS.GET_STAFF_TIME_CLOCK_REPORT}`, {
            "staffId": staffIds,
            "startDate": moment(startDates).format("YYYY-MM-DD"),
            "endDate": moment(endDates).format("YYYY-MM-DD"),
            "reason": reason
        });
        if (clockData && clockData.status) {
            setStaffTimeClock(clockData.resultData.staffTimeClock)
        }
        setLoadingClockList(false)
    }
    async function excelExport() {
        setLoading(true)
        const clockData = await customAxios.post<any, any>(`${API_ENDPOINTS.GET_STAFF_TIME_CLOCK_REPORT_EXCEL}`, {
            "staffId": fields?.staffId?.id,
            "startDate": moment(fields.startDate).format("YYYY-MM-DD"),
            "endDate": moment(fields.endDate).format("YYYY-MM-DD"),
            "reason": fields?.reason?.name
        });
        if (clockData && clockData.status) {
            if (clockData?.resultData?.reportUrl != "" && clockData?.resultData?.reportUrl != null) {
                window.open(clockData?.resultData?.reportUrl)
            }
        }
        setLoading(false)
    }
    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        latestDataRef.current = value;
        if (value == "" && (name === "startDate" || name === "endDate")) {
            value = null;
        }
        const newAdvSearchFileds = {
            ...fields,
            [name]: value,
        };
        // flushSync(() => {setFields(newAdvSearchFileds)})
        setFields(newAdvSearchFileds);

        if (name === 'staffId') {
            fetchClockTimeList(value.id, fields.startDate, fields.endDate, fields.reason ? fields.reason.name : "")
        }
        if (name === 'reason') {
            fetchClockTimeList(fields?.staffId ? fields?.staffId?.id : 0, fields.startDate, fields.endDate, value.id)
        }

    };

    const renderHeaderFilters = () => {

        return (
            <div className="row align-items-center searchBoxShadow mx-0 blue_theme blueTheme">
                {loading && <GridLoader />}
                <div className="col-lg-9 col-sm-12">
                    <div className="content-search-filter  px-0 filter-drop-down">
                        {/*start click major filter */}
                        <div className="major-filter">
                            <div
                                onClick={() => setOpenDateFilter(true)}

                                onBlur={() => {
                                    if (!applyButtonClicked) {
                                        setOpenDateFilter(false);
                                    }
                                }}
                                className="service-main  authCalender dropdown email-filter border-dashed-cus position-relative"
                            >
                                <button className="btn  btn-size-cus " type="button">
                                    Date{" "}
                                    <span className="border-spann">
                                        {moment(fields?.startDate).format("MM/DD/yyyy") +
                                            " - " +
                                            moment(fields?.endDate).format("MM/DD/yyyy")}
                                    </span>
                                </button>



                                {openDateFilter && (
                                    <div className="dropdown-service auth_drop_service "
                                        onFocus={() => setOpenDateFilter(true)}>
                                        <div className="row staffButton billingFilterRow">
                                            <div className="col-md-6 position-relative">
                                                <DatePickerKendoRct
                                                    validityStyles={staffError}
                                                    value={fields.startDate && new Date(fields.startDate)}
                                                    onChange={handleChange}
                                                    name="startDate"
                                                    label="Start Date"
                                                    placeholder="Start Date"
                                                    error={errors.startDate && errors.startDate}
                                                    required={true}
                                                />
                                                <img
                                                    src={calender}
                                                    alt=""
                                                    className="leftIcon "
                                                    style={{ left: "19px", top: "30px" }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <DatePickerKendoRct
                                                    validityStyles={staffError}
                                                    value={fields.endDate && new Date(fields.endDate)}
                                                    onChange={handleChange}
                                                    name="endDate"
                                                    label="End Date"
                                                    placeholder="End Date"
                                                    error={errors.endDate && errors.endDate}
                                                    required={true}
                                                />
                                                <img
                                                    src={calender}
                                                    alt=""
                                                    className="leftIcon "
                                                    style={{ left: "19px", top: "30px" }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-2"

                                            onMouseDown={() => setApplyButtonClicked(true)}
                                            onMouseUp={() => setApplyButtonClicked(false)}
                                        >

                                            <div className="col-12"
                                            >
                                                <div className="text-end">
                                                    <button onClick={handleApplyFilter} type="button" className="submitButon">
                                                        Apply
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* create click2 start */}





                            {
                                isHumanResourcesManager ||
                                    userIsSuperAdmin(staffLoginInfo?.roleId)
                                    || userIsAdmin(staffLoginInfo?.roleId) ?
                                    <>
                                        {
                                            (loadingStaff) ?
                                                <TableCell component="th" scope="row" width={200}>
                                                    <Skeleton animation="wave" variant="text" />
                                                </TableCell> : <div
                                                    onClick={(event: any) => {

                                                        if (!refStaffClose.current?.contains(event.target)) {
                                                            setOpenStaffFilter(true)
                                                        }
                                                    }}
                                                    ref={refStaff}
                                                    className="service-main dropdown email-filter border-dashed-cus position-relative"
                                                >
                                                    <button className="btn  btn-size-cus pl-0 " type="button">
                                                        {fields?.staffId != null && fields?.staffId.name ?
                                                            <i ref={refStaffClose}
                                                                onClick={handleClearStaff}
                                                                className={"fa fa-times cross-icon mr-2 "}
                                                            ></i>
                                                            :
                                                            <i className={"fa fa-plus cross-icon "}></i>}

                                                        Staff {fields?.staffId != null && fields?.staffId.name && (

                                                            <span
                                                                className={fields?.staffId != null && fields?.staffId.name ? 'border-spann' : ''}
                                                            >
                                                                {fields?.staffId.name
                                                                }
                                                            </span>
                                                        )}
                                                    </button>

                                                    {openStaffFilter && (
                                                        <div className="dropdown-service dropDates ">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <DropDownList
                                                                        data={(filter && filter.staffList && filter.staffList != null) ? filterBy(staffList, filter.staffList) : staffList}
                                                                        onFilterChange={(event) => {
                                                                            onFilterChange(event, "staffList");
                                                                        }}
                                                                        textField="name"
                                                                        label="Staff Name"
                                                                        name="staffId"
                                                                        value={fields?.staffId}
                                                                        onChange={handleChange}

                                                                        dataItemKey={"id"}

                                                                        filterable={true}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                        }
                                    </> : <div></div>
                            }


                            {
                                isHumanResourcesManager ||
                                    userIsSuperAdmin(staffLoginInfo?.roleId)
                                    || !userIsAdmin(staffLoginInfo?.roleId) ?
                                    <>
                                        {
                                            (loadingStaff) ?
                                                <TableCell component="th" scope="row" width={200}>
                                                    <Skeleton animation="wave" variant="text" />
                                                </TableCell> :
                                                <div
                                                    onClick={(event: any) => {

                                                        if (!refReasonClose.current?.contains(event.target)) {
                                                            setOpenReasonFilter(true)
                                                        }
                                                    }}
                                                    ref={refReason}
                                                    className="service-main dropdown email-filter border-dashed-cus position-relative"
                                                >
                                                    <button className="btn  btn-size-cus pl-0 " type="button">
                                                        {fields?.reason != null && fields?.reason?.name ?
                                                            <i ref={refReasonClose}
                                                                onClick={handleClearReason}
                                                                className={"fa fa-times cross-icon mr-2 "}
                                                            ></i>
                                                            :
                                                            <i className={"fa fa-plus cross-icon "}></i>}

                                                        Reason {fields?.reason != null && fields?.reason?.name && (

                                                            <span
                                                                className={fields?.reason != null && fields?.reason ? 'border-spann' : ''}
                                                            >
                                                                {fields?.reason?.name
                                                                }
                                                            </span>
                                                        )}
                                                    </button>

                                                    {openReasonFilter && (
                                                        <div className="dropdown-service dropDates ">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <DropDownList
                                                                        data={(filter && filter.reasonData && filter.reasonData != null) ? filterBy(reasonData, filter.reasonData) : reasonData}
                                                                        onFilterChange={(event) => {
                                                                            onFilterChange(event, "reasonData");
                                                                        }}
                                                                        textField="name"
                                                                        label="Reason"
                                                                        name="reason"
                                                                        value={fields?.reason?.name}
                                                                        onChange={handleChange}

                                                                        dataItemKey={"id"}

                                                                        filterable={true}
                                                                    />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                        }
                                    </> : <div></div>
                            }






                        </div>

                    </div>
                </div>

                <div className="col-lg-3 col-sm-12 d-flex justify-content-end clear-add-filter">
                    <div className="mr-3">
                        <p className="mb-0" onClick={clearAllFilter}>
                            Clear Filter
                        </p>
                    </div>

                </div>


            </div>
        )

    };

    const renderTimeByStaff = () => {
        return (<>
            {
                staffTimeClock.map((datStaffClock: any) => {
                    return (<>
                        {(datStaffClock && datStaffClock.length > 0) &&
                            <div className="row timeClockGridHeader">
                                <div className="col-lg-12">
                                    <span style={{ float: 'left', margin: 5 }} ><b>{datStaffClock[0]?.staff}</b></span>
                                    <span style={{ float: 'right', margin: 5 }}><b>Total Hrs: {datStaffClock[0]?.totalTimeStaff}</b></span>
                                </div>
                            </div>
                        }
                        <div className="row">
                            {
                                renderTimeLog(datStaffClock)
                            }
                        </div>

                    </>)
                })
            }

        </>)
    }

    function toHoursAndMinutes(totalMinutes) {
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;
        var hoursStr = hours > 0 ? `${hours}h ` : "";
        var minutesStr = minutes > 0 ? `${minutes}m` : "";
        const formatedTime = `${hoursStr}${minutesStr}`;
        return formatedTime;
    }

    const renderTimeLog = (gridData) => {
        return (<>
            <Grid
                data={gridData}
                style={{ height: "100%" ,overflow: "scroll" }}
                filter={filter}>

                <GridColumn
                    title="Time In"
                    cell={(props) => {
                        let field = props.dataItem.isCustomClock ?
                            (props.dataItem.clockIn)
                            : moment.utc(props.dataItem.clockIn).local().format("M/D/YYYY hh:mm A");

                        return <td>{(props.dataItem.clockIn && props.dataItem.clockIn != null) ? moment.utc(props.dataItem.clockIn).local().format("M/D/YYYY hh:mm A") : '-'}</td>;
                    }}
                />
                <GridColumn
                    title="Time Out"
                    cell={(props) => {
                        let field = props.dataItem.isCustomClockOut ?
                            (props.dataItem.clockOut)
                            : moment.utc(props.dataItem.clockOut).local().format("M/D/YYYY hh:mm A");
                        return <td>{(props.dataItem.clockOut && props.dataItem.clockOut != null) ? moment.utc(props.dataItem.clockOut).local().format("M/D/YYYY hh:mm A") : '-'}</td>;
                    }}
                />
                <GridColumn
                    title="Hours Worked"
                    cell={(props) => {

                        let fieldStr = props.dataItem.durationTime
                        if (!fieldStr || fieldStr == null) {
                            var startDate = moment(new Date());
                            var endDate = moment.utc(props.dataItem.clockIn).local()
                            fieldStr = toHoursAndMinutes(startDate.diff(endDate, 'minutes'))
                        }
                        return <td>{(fieldStr && fieldStr != null) ? fieldStr : '0 m'}</td>;
                    }}
                />
                <GridColumn
                    field="reason"
                    title="Reason"
                />
                <GridColumn
                    field="description"
                    title="Description"
                    className="textWRapper"
                />
            </Grid></>)
    }



    return (
        <>
            {
                (userIsSuperAdmin(staffLoginInfo?.roleId) || userIsAdmin(staffLoginInfo?.roleId)
                    || staffLoginInfo.isHumanResourcesManager) ?
                    <div className="grid-table  filter-grid">
                        <button
                            type="button"
                            value="BACK"
                            onClick={() => {
                                navigate(-1)
                            }}
                            className="border-0 bg-transparent arrow-rotate mb-3"
                        >
                            <i className="k-icon k-i-sort-asc-sm"></i>
                            Back
                        </button>
                        <div className="d-flex " style={{ justifyContent: "space-between" }}>
                            <div className="col-lg-3 col-sm-3">
                                <h6 className="f-24">Time Clock Report </h6>
                            </div>
                            <div className="col-lg-3 col-sm-3">
                                <button className="submitButon" style={{ float: "right" }}
                                    onClick={excelExport}><i className="fa-regular fa-file-excel mr-1"></i>Export to Excel</button>
                            </div>
                        </div>
                        <div className="">
                            <div className="row">
                                <div className="col-lg-12"> {renderHeaderFilters()}</div>
                            </div>
                            <br></br>
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="staff-profile-page blueThemeTable blueTheme blue_theme" style={{ margin: 15 }}>
                                        {
                                            loadingClockList ? <div><TableRowsLoader rowsNum={12} /></div> :
                                                <div>
                                                    <div className="client-accept gridCursor">
                                                        <div className="address-line-content mt-3  table-heading-auth">
                                                            {renderTimeByStaff()}
                                                            <br></br>
                                                        </div>
                                                    </div>

                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <AccessDenied></AccessDenied>
            }
        </>
    )

}

export default GenerateTimeClockReport