import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingTemplateReport from "./settingTemplateReport";
import { getReportColumns, getStaffReportColumnPreferences } from "src/redux/actions";

function Tab1({ selectedReport }: { selectedReport: number }) {
  const { staffReportColumnPrefResp, staffReportWithTypeResp } = useSelector(
    (state: any) => state.StaffReducer
  );
  const [setting, setSetting] = useState(false);
  const [activeColumns, setActiveColumns] = useState([]);
  const staffId = useSelector((state: any) => state.loggedIn?.staffId);
  const dispatch = useDispatch();

  function handleSettingTemplateView() {
    setSetting(!setting);
  }

  const CustomTitleBar = () => {
    return (
      <div style={{paddingLeft: "20px", cursor: 'pointer' }} onClick={() => {handleSettingTemplateView();}}>
            <Tooltip anchorElement="target" position="top">
              <i className="cursor-pointer fa fa-cog" title="Setting" />
            </Tooltip>
      </div>
    );
  };

  // const dataToDisplay = staffReportColumnPrefResp.length > 0 ? [staffReportColumnPrefResp[0]] : [{}];

  useEffect(() => {
    if(staffReportWithTypeResp.length > 0) {
      const data = {
        reportId: selectedReport,
        staffId: staffId
      }
      const data1 = {
        reportId: selectedReport
      }
      dispatch(getReportColumns(data1))
      dispatch(getStaffReportColumnPreferences(data))
    }
  }, [staffReportWithTypeResp])

  // const activeColumns = staffReportColumnPrefResp.filter(
  //   (col: any) => col.isActive
  // );

  useEffect(() => {
    if(staffReportColumnPrefResp.length > 0) {
      setActiveColumns(staffReportColumnPrefResp.filter((item: any) =>
        item.isActive === true
      ))
    }
    else {
      setActiveColumns([]);
    }
  }, [staffReportColumnPrefResp])

  return (
    <>
      <div className="grid-table blueThemeTable filter-grid ml-2 cursor-default">
        {(activeColumns.length > 0) ?
        <Grid
          className="cursor-default"
          style={{
            height: "100%",
          }}
          // data={dataToDisplay}
        >

          {activeColumns.map((col: any) => (
            <GridColumn
              width = "150px"
              key={col.id}
              title={col.uiColumnName}
              field={col.uiColumnName.replace(/\s+/g, "")}
              className="cursor-default wrap-text"
            />
          ))}

        <GridColumn
            sortable={false}
            width="70px"
            title={<CustomTitleBar/>}
          />
        </Grid>
        : <div>No column found</div>}
      </div>
      {setting && (
        <SettingTemplateReport
          onClose={handleSettingTemplateView}
          selectedReport={selectedReport}
        />
      )}
    </>
  );
}

export default Tab1;
