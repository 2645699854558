const ERR_MESSAGE = {
  SOMETHING_WRONG: "Something went wrong try again after sometime.",
  FIELD_BLANK: "*Required",
  FIELD_DATE_VALID: "Clock Out Date time can not be before Clock in Date time",
  NUMBER_ONLY: "Enter Only Number.",
  INVALID_EMAIL: "Enter Valid Email.",
  INVALID_NUMBER: "Enter Valid Number",
  USER: "Username is Required",
  FIRST_NAME: "First Name is Required",
  NPI: "NPI is Required",
  POSITION: "Position is Required",
  MIDDLE_NAME: "Middle Name is Required",
  GREATER_THAN: "Enter less than five digits.",
  USER_NAME: "Username is Required.",
  OTP_BLANK: "otp cant be blank.",
  BLANK_NEW_PASSWORD: "New Password Can't Be Blank.",
  PASSWORD_CONTAIN_CHECK:
    "Your password must include at least one number, one letter, and one special character (ex. notenetic@2022)",
  PASSWORD_COUNT_CHECK: "password should contains atleast 8 charachters",
  CONFIRM_PASSWORD_BLANK: "Confirm Password Can't Be Blank.",
  PASSWORD_DIDNT_MATCH: "Confirm Password Didnt Match",
  WEIGHT: "Weight is Required",
  HEIGHT: "Height is Required",
  HAIR_COLOR: "Hair Color is Required",
  EYE_COLOR: "Eye Color is Required",
  SIB_CLIENT: "Client is Required",
  LAST_NAME: "Last Name is Required",
  LOCATION: "Location is Required",
  RECORD_ID: "Record Id Is Required",
  CURRENT_PASSWORD_BLANK: "Current Password Can't Be Blank.",
  COMPANY_NAME: "Company Name is Required",
  NICK_NAME: "Nick Name is Required",
  GENDER: "Gender is Required",
  DOB: "Date of Birth is Required",
  SSN: "Social Security Number is Required",
  RACE: "Race is Required",
  RELATION: "Relation is Required",
  ADDRESS_LINE_ONE: "Address Line 1 is Required",
  ADDRESS_LINE_TWO: "Address 2 is Required",
  CITY: "City is Required",
  STATE: "State is Required",
  ZIP_CODE: "Zip is Required",
  MOBILE_PHONE: "Mobile Phone is Required",
  EMAIL: "Email is Required",
  INSURANCE_TYPE: "Insurance Type is Required",
  POLICY: "Policy is Required",
  START_DATE: "Start Date is Required",
  END_DATE: "End Date is Required",
  ASSIGN_SITES: "Assign Site is Required",
  PIN_LENGTH: "Pin Should Have Atleast 4 digits",
  PIN_MATCH: " Pin and Confirm Pin Doesn't match",
  INVALID_PIN: "Invalid Pin.",
  USERNAME_AVAILABLE: "Username Already Taken. Try Any Other ",
  POSTION: "Position is Required",
  ROLE_ID: "Role is Required",
  POSITION_EFFECTIVE: "Position Effective Date is Required",
  TIMEZONE: "Timezone is Required",
  MARITIAL_STATUS: "Maritial Status is Required",
  POSITION_NAME: "Position Name is Required",
  POSITION_EFFECTIVE_DATE: "Effective Date is Required",
  POSITION_END_DATE: "Position End Date is Required",
  DIAGNOSIS_DATE: "Diagnosis Date is Required",
  DIAGNOSIS_NAME: "Diagnosis Name is Required",
  CERTIFICATE: "Certificate Name is Required",
  ISSUE_DATE: "Issue Date is Required",
  EXPIRATION_DATE: "Expiration Date is Required",
  DOCUMENT_NAME: "File Name is Required",
  UPLOAD_DOCUMENT: "Documnet is Required",
  EMAIL_FORMAT: "Email format is not correct",
  siteName: "Site is Required",
  ADDRESS: "Address is Required",
  PHONE: "Phone Number is Required ",
  PHONE_LIMIT: "Input 10 Digits of Phone Number",
  fax: "Fax is Required",
  email: "Email is Required ",
  licenses: "License is Required",
  comments: "Comments is Required",
  SELECTEDSTAFF: "Staff is Required",

  CLIENT_ID: "Client is Required",
  COMMENT: "Comment is Required",

  bpSys: "BP sys is Required",
  bpDia: "BP dias is Required",
  heartRate: "Heart rate is Required",
  pulseRate: "Pulse rate is Required",
  temperature: "Temperature is Required",
  respiration: "Respiration is Required",
  height: "Height is Required",
  weight: "Weight is Required",
  dateRecord: "Record Date is Required",
  endDate: "End Date is Required ",

  CREDENTIAL_NAME : "Credentials Name is Required",
  CREDENTIAL_EFFECTIVE_DATE: "Effective Date is Required",
  CREDENTIAL_END_DATE: "End Date is Required",
  

  // ------------------------------insurance Error--------------------------

  INSURANCETYPE: "Insurance type is Required",
  POILICY: "Policy # is Required",
  DIAGNOSIS_UPDATE_DATE: "Diagnosis is not available for this Date",

  //----------------------------Service Error......................
  serviceRate: "Service Rate is Required",
  service: "Service is Required",
  serviceCode: "Service Code is Required",
  dateEffective: "Date Effective is Required",
  rateDateEffective: "Rate Date Effective is Required",
  billingUnitId: "Billing unit is Required",
  rateEndDate: "Rate End Date is Required",
  REF_NAME: "Referral Name is Required",
  REF_DATE: "Referral Date is Required",
  REASON: "Reason is Required",
  PATTERN: "Please Match The Format Requested",
  CERTIFICATE_NAME: "Certification Updated Successfully",
  PRIMARY_CARE_PHYSICIAN_NAME: "Primary Care Physician Name is Required",
  FLAG_NAME: "Flag Name is Required",
  FLAG_COLOR: "Select Flag color",
  TAG_NAME: "Tag Name is Required",
  STATUS_NAME: "Status Name is Required",
  BILLING_STATUS: "Billing Status Name is Required",
  TAG_COLOR: "Select Tag Color",
  MIN_CHARACTER_REQUIRED: "Min Three Character Required",
  PHONE_PATTERN: "White Space Not Allowed ",
  DATE_CHECK: "Selected Date can't be greater then current Date",
  NAME: "Name is Required",
  PEDIATRICATION: "Pediatrician Name is Required",
  INVALID_NPI_NUMBER: "NPI is invalid",
  DAY: "Day is Required",
  GREATER_THEN_ZERO: "Cannot be 0",
  WHITE_SPACE: "Space Not Allowed",
  CLIENT_START_DATE: "Client Start Date is Required",
  TARGET_DATE: "Target Date is Required",
  TOTAL_UNIT: "Total Units is Required",
  ACCESS_LEVEL: "Access Level is Required",
  DOCUMENT_ID: "Document is Required",
  PAYROLL_SELECTED_DATE: "Please select Payroll paid date.",
  PAYROLL_BILL_STATUS: "Please select bill status",
  REVIEW_DATE: "Review Date is Required",
  SKILL_NAME: "Skill Name is Required",
  CATEGORY_NAME: "Category Name is Required",
  CHECKBOX_REQUIRED: "Attestation is Required",

  CONTACT_TYPE: "Contact Type is Required",
  VALID_PHONE:"Please enter a valid phone number",

  BILLING_STATUS: "Billing Status  is Required",
  BUSINESS_START_HOUR:"Start time is required",
  BUSINESS_END_HOUR:"End time is required",
  BUSINESS_START_END_HOUR:"End time must be greater than start time"
};

export default ERR_MESSAGE;
